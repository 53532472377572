import React, { lazy, Suspense } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import Layout from "./component/Layout";

const CompleteYourRing = lazy(() => import("./component/ApplicationPreview/CompleteYourRing"));
const MinedTab = lazy(() => import("./component/ApplicationPreview/SelectYourDiamond/MinedTab"));
const Lab = lazy(() => import("./component/ApplicationPreview/SelectYourDiamond/LabGrown"));
const FancyTab = lazy(() => import("./component/ApplicationPreview/SelectYourDiamond/FancyColor"));
const Compare = lazy(() => import("./component/ApplicationPreview/SelectYourDiamond/Compare"));
const Request = lazy(() => import("./component/ApplicationPreview/SelectYourDiamond/Request"));
const ColorPanel = lazy(() => import("./component/ColorPanel"));

const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <Routes>
          <Route path="/viewDiamondDetails" element={<CompleteYourRing />} />
          <Route exact path="/" element={<PrivateRoute />}>
            <Route path="/Mined" element={<MinedTab />} />
            <Route path="/Lab" element={<Lab />} />
            <Route path="/Fancy" element={<FancyTab />} />
            <Route path="/Request" element={<Request />} />
            <Route path="/Compare" element={<Compare />} />
          </Route>
        </Routes>
        <ColorPanel></ColorPanel>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
