import React, { useState, useEffect, useLayoutEffect } from "react";
import { Spin,Modal ,Tooltip} from "antd";
import ResetIcon from "../../../assets/images/choose-setting/reset__icon.svg";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  handleResetMinedPage,
  handleResetLabPage,
  handleResetFancyPage,
  handleResetRequestPage,
  handleSetSaveSearchMinedTab,
  handleSetSwitchFilter,
  handleSetSaveSearchLabTab,
  handleSetSwitchLabFilter,
  handleSetSaveSearchFancyTab,
  handleSetSwitchFancyFilter,
  handleComaparePageIds,
  handleResetLabToggle,
  handleResetFancyToggle,
  handleResetMinedToggle
} from "../../../actions/selectyourdiamond/selectYourDiamond";

import {
  handleSetLoginDetails,
  handleResetApp
} from "../../../actions/LoginDetails/loginDetails";
import { handleSetPage } from "../../../actions/PageNavigations/PageNavigations";
import { useLocation } from "react-router-dom";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import styled from "styled-components";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useNavigate } from "react-router-dom";
import colorPanelService from "../../../services/color-panel.service";

const SelectYourDiamond = () => {
  const navigate = useNavigate();
  const pageNavigation = useSelector((state) => state.pageNavigationReducer);
  const CompareIds = useSelector(
    (state) => state.selectYourDiamondReducer.compareIds
  );
  const dispatch = useDispatch();
  const search = useLocation().search;
  const [navigationData, setNavigationData] = useState([]);
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const dealerId = new URLSearchParams(search).get("DealerLink");
  const isReset = new URLSearchParams(search).get("ResetIFrame");
  const isChange = new URLSearchParams(search).get("isChange");
  const [noDiamondToCompare, setNoDiamondToCompare] = useState(false);
  useEffect(() => {
    dispatch(handleSetLoginDetails(dealerId));
    handleGetNavigationData(dealerId);
  }, []);
  const [key, setKey] = useState("Mined");

  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  useEffect(() => {
    if (isReset == "true") {
      dispatch(handleResetApp());
      if (dealerId) {
        dispatch(handleSetLoginDetails(dealerId));
      }
    }
  }, [isReset]);

  const handleCloseNoDiamondModal = () => {
     setNoDiamondToCompare(false);
  };

  useEffect(() => {
    if (loginDetails) {
      if (loginDetails.dealerId) {
        if (loginDetails.dealerId !== dealerId) {
          dispatch(handleResetApp());
          dispatch(handleSetLoginDetails(dealerId));
        }
      }
    }
  }, [dealerId]);

  useEffect(() => {
    if (pageNavigation) {
      setKey(pageNavigation.key);
    }
  }, [pageNavigation]);

 const handlTabChanges = (val) => {
  if (val === "Compare" && CompareIds.length === 0) {
    setNoDiamondToCompare(true);
    return; // Prevent changing the active tab
  }

  setKey(val);

  if (val == "Mined") {
    navigate("/Mined" + `?DealerLink=${dealerId}`);
  } else if (val == "Lab Grown") {
    navigate("/Lab" + `?DealerLink=${dealerId}`);
  } else if (val == "Fancy Colored") {
    navigate("/Fancy" + `?DealerLink=${dealerId}`);
  } else if (val == "Request") {
    navigate("/Request" + `?DealerLink=${dealerId}`);
  } else if (val == "Compare") {
    let compareIds = CompareIds.toString();
    navigate(
      "/Compare" +
        `?DealerLink=${dealerId}&CompareIds=${compareIds}&ctype=${
          key == "Fancy Colored" ? "CD" : "D"
        }`
    );
  } else {
    navigate("/Mined" + `?DealerLink=${dealerId}`);
  }
  if (val !== "Compare") {
    dispatch(handleComaparePageIds(""));
  }
};
const tooltipContent = {
  "Mined": "Formed over billions of years, natural diamonds are mined from the earth. Diamonds are the hardest mineral on earth, which makes them an ideal material for daily wear over a lifetime. Our natural diamonds are conflict-free and GIA certified.",
  "Lab Grown": "Lab-grown diamonds are created in a lab by replicating the high heat and high pressure environment that causes a natural diamond to form. They are compositionally identical to natural mined diamonds (hardness, density, light refraction, etc), and the two look exactly the same. A lab-grown diamond is an attractive alternative for those seeking a product with less environmental footprint.",
  "Fancy Colored": "Also known as fancy color diamonds, these are diamonds with colors that extend beyond GIA’s D-Z color grading scale. They fall all over the color spectrum, with a range of intensities and saturation. The most popular colors are pink and yellow.",
};
  const handleTabName = () => {
    if (window.location.href.indexOf("Request") > -1) {
      setKey("Request");
    } else if (window.location.href.indexOf("Lab") > -1) {
      setKey("Lab Grown");
    } else if (window.location.href.indexOf("Fancy") > -1) {
      setKey("Fancy Colored");
    } else if (window.location.href.indexOf("Mined") > -1) {
      setKey("Mined");
    } else {
      setKey("Compare");
    }
  };

  const handleSaveSearchForPage = () => {
    if (key == "Mined") {
      dispatch(handleSetSaveSearchMinedTab(true));
      dispatch(handleResetMinedPage(false));
      dispatch(handleSetSwitchFilter());
    }
    if (key == "Lab Grown") {
      dispatch(handleSetSaveSearchLabTab(true));
      dispatch(handleResetLabPage(false));
      dispatch(handleSetSwitchLabFilter());
    }
    if (key == "Fancy Colored") {
      dispatch(handleSetSaveSearchFancyTab(true));
      dispatch(handleResetFancyPage(false));
      dispatch(handleSetSwitchFancyFilter());
    }
    NotificationManager.success("Search Saved Successfully.");
  };

  const handleGetNavigationData = async (id) => {
    let inputData = {
      dealerLink: parseInt(id)
    };
    setLoading(true);
    try {
      await SelectYourDiamondService.GetnNavigationList(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (responseData) {
            let navigationArray = [];
            if (responseData.labData) {
              navigationArray.push(responseData.labData[0]);
            }
            if (responseData.fancyData) {
              navigationArray.push(responseData.fancyData[0]);
            }
            if (responseData.minedData) {
              navigationArray.push(responseData.minedData[0]);
            }
            if (responseData.requestDiamondData) {
              navigationArray.push(responseData.requestDiamondData[0]);
            }
            if (responseData.compareDiamondData) {
              navigationArray.push(responseData.compareDiamondData[0]);
            }

            let newArray = [];
            for (let i = 0; i < navigationArray.length + 1; i++) {
              newArray.push((i + 1).toString());
            }
            setOrderNumbers(newArray);

            let newminArr = navigationArray
              .filter((x) => x.setting == "True")
              .map((x) => {
                return x.order;
              });
            let val = Math.min(...newminArr);
            let newActiveTab = navigationArray
              .filter((item) => {
                return item.setting == "True" && item.order == val;
              })[0]
              .name.toString();
            if (isChange == "True") {
              if (newActiveTab == "Mined") {
                navigate("/Mined" + `?DealerLink=${dealerId}`);
              } else if (newActiveTab == "Lab Grown") {
                navigate("/Lab" + `?DealerLink=${dealerId}`);
              } else if (newActiveTab == "Fancy Colored") {
                navigate("/Fancy" + `?DealerLink=${dealerId}`);
              } else if (newActiveTab == "Request") {
                navigate("/Request" + `?DealerLink=${dealerId}`);
              } else if (newActiveTab == "Compare") {
                navigate("/Compare" + `?DealerLink=${dealerId}`);
              } else {
                navigate("/Mined" + `?DealerLink=${dealerId}`);
              }
              dispatch(handleSetPage(newActiveTab));
              setKey(newActiveTab);
            } else {
              handleTabName();
            }

            setNavigationData(navigationArray);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: ""
  });
  const [toggle, setToggle] = useState(true);
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  useLayoutEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "DiamondLink",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: ""
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: ""
          };

          if (msg == "Success") {
            if (initialColorObj) {
              let tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };
  const handleResetForPage = () => { 
    if (key == "Mined") {
      dispatch(handleResetMinedToggle());
      dispatch(handleResetMinedPage(true));
      dispatch(handleSetSaveSearchMinedTab(false));
    }
    if (key == "Lab Grown") {
      dispatch(handleResetLabToggle());
      dispatch(handleResetLabPage(true));
      dispatch(handleSetSaveSearchLabTab(false));
    }
    if (key == "Fancy Colored") {
      dispatch(handleResetFancyToggle());
      dispatch(handleResetFancyPage(true));
      dispatch(handleSetSaveSearchFancyTab(false));
    }
    if (key == "Request") {
      dispatch(handleResetRequestPage());
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */

  function getTextStyle(name) {
    if (key == name) {
      return {
        color: "#fff",
        background: `${varColorObj.columnHeaderAccentColor}`
      };
    }
  }

  ///Color files end

  return (
    <React.Fragment>
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <Spin spinning={loading || wrapperLoading}>
          <div className="row">
            <div className="col-md-12">
              <div className="prodetail__section">
                <div className="col-md-12">
                  <div className="prod__maindiv app__preview p-0 border-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="prodesc__div">
                          <div className="tright__btns">
                            {key == "Compare" || key == "Request" ? null : (
                              <p onClick={handleSaveSearchForPage}>
                                <img />
                                <span>Save Search</span>
                              </p>
                            )}
                            {key == "Compare" ? null : (
                              <p onClick={handleResetForPage}>
                                <img src={ResetIcon} />
                                <span>Reset</span>
                              </p>
                            )}
                          </div>
                          <div className="custom__tab">
                            <ul>
                              {orderNumbers.map((order) => {
                                return navigationData.map((tab,index) => {
                                  const isTooltipRequired = index < 3;
                                  const tooltipText = isTooltipRequired ? tooltipContent[tab.name] || "" : "";
                                  return (
                                    tab.setting == "True" &&
                                    order == tab.order && (
                                      <li
                                        style={getTextStyle(tab.name)}
                                        onClick={() =>
                                            handlTabChanges(tab.name)
                                          }
                                          >
                                            <span>
                                              {tab.name == "Compare"
                                                ? key == "Compare"
                                                  ? "Compare"
                                                  : "Compare" +
                                                    " " +
                                                    "(" +
                                                    CompareIds.length +
                                                    ")"
                                                : tab.name == "Fancy Colored"
                                                ? tab.name.split(/(?=[A-Z])/)[0] +
                                                  " " +
                                                  tab.name.split(/(?=[A-Z])/)[1]
                                                : tab.name}
                                            </span>
                                            {isTooltipRequired && (
                                                    <Tooltip
                                                      title={tooltipText}
                                                      placement="rightTop"
                                                    >
                                                      <img
                                                        src={UnionIcon}
                                                        alt=""
                                                        style={{ marginLeft: '8px', cursor: 'pointer' ,width:"14px", backgroundColor:'white' , borderRadius:'50%' }} 
                                                      />
                                                    </Tooltip>
                                            )}
                                      </li>            
                                    )
                                  );
                                });
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    <Modal
        title="Alert"
        className="datamapping__modal"
        width={400}
        centered
        visible={noDiamondToCompare}
        onCancel={handleCloseNoDiamondModal}
        maskClosable={false}
        okText="Ok" 
        onOk={handleCloseNoDiamondModal}
        cancelButtonProps={{ style: { display: "none" } }} 
      >
        <div className="form__fields row border-0 p-0">
          <div className="col-lg-12 text-center">
            <p> Kindly Select a diamond to Compare </p>
          </div>
        </div>
    </Modal>


    </React.Fragment>
  );
};

export default SelectYourDiamond;
